<template>
  <div id="app">

<!--    <Header></Header>-->
<!--    <AppTabbar v-show="$route.meta.isShowTabbar"/>-->
    <router-view/>
  </div>
</template>

<script>
import AppTabbar from '@/components/AppTabbar'
import Header from '@/components/Header'
export default {
  components:{
    AppTabbar,
    Header
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
  min-height: 100%;
  font-size: .14rem ;
}
html,body {
  height: 100%;
}
html {
  font-size: 100px !important;   // 16px?? 
}



</style>
