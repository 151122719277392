<template>
  <div class="header">
    <div class="header-logo"><img src="https://m.redsun360.com/resource/logo.png" alt="" class="logo"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
}
</script>

<style scoped>
.header {
  background: #985C1D;
  text-align: center;
  position: relative;
}

.header-logo {
  margin: 0 auto;
}

.logo {
  width: 50px;
  height: 50px;
}

</style>