<template>
  <div class="home">
        <Header></Header>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="darkred">
      <van-swipe-item v-for="item in banner" :key="item.id">
        <img :src="item.image_url" width="100%" style="display: block;" alt/>
      </van-swipe-item>
    </van-swipe>
    <div class="article" v-for="item in articleList">
      <div class="article-top">
        <div>
          <img src="../assets/circle.png" alt="" style="width: 10px;height: 10px;">
        </div>
        <p class="article-heard">{{ item.categoryName }}</p>
      </div>
      <div class="article-bottom">
        <img :src="item.img" alt="">
        <p class="article-title">{{ item.title }}</p>
        <p>{{ item.description }}</p>
      </div>
    </div>
    <div>
      <img src="https://img.redsun360.com/m/about.png" alt="" width="100%">
    </div>
  </div>
</template>

<script>
import {getMpIndexData} from '@/request/api'
import Header from '@/components/Header'
export default {
  components:{
    Header
  },
  name: 'Home',
  data() {
    return {
      SearchValue: "",
      show: false,
      articleList: [],
      banner: [{image_url: 'https://img.redsun360.com/m/banner02.png'}, {image_url: 'https://img.redsun360.com/m/banner01.png'},],
    };
  },
  created() {
    this.getMpIndex()
  },
  methods: {
    getMpIndex() {
      getMpIndexData().then(res => {
        if (res.data.success) {
          this.articleList = res.data.data.articleList
          console.log(this.articleList);
        }
      })
    }
  },


}
</script>
<style lang="less">
.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

.article {
  width: 95%;
  background: #fff;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 10px;
}

.article img {
  width: 100%;
}

.article-bottom {
  padding: 0 10px 10px 10px;
}

.article-top {
  padding: 10px;
  display: flex;
  align-content: center;
}

.article-heard {
  padding-left: 5px;
}

.article-bottom p {
  line-height: 1.5;
}

.article-title {
  font-weight: 600;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }


</style>
