<template>
  <div>
      <van-tabbar v-model="$route.meta.activeNum">
          <van-tabbar-item to="/home" icon="home-o">首页</van-tabbar-item>
          <van-tabbar-item to="/topic" icon="label-o">专题</van-tabbar-item>
          <van-tabbar-item to="/category" icon="apps-o">分类</van-tabbar-item>
          <van-tabbar-item to="/cart" icon="cart-o">购物车</van-tabbar-item>
          <van-tabbar-item to="/user" icon="user-o">我的</van-tabbar-item>
      </van-tabbar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  computed: {
    active() {
      return this.$route.meta.activeNum
    }
  }
}
</script>
 
<style lang = "less" scoped>
  
</style>